import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import {Redirect} from "react-router-dom";

class Page404 extends Component {
  constructor(){
    super();
    this.state = {
      redirectToHome: false,
    };
    this.home = this.home.bind(this);
  }

  home()
  {
    this.setState({redirectToHome:true});
  }

  render() {
    if(this.state.redirectToHome)
    {
      return (<Redirect to={'/'}/>)
    }
    else
    {
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6" className="bg-white p-5 rounded shadow">
                <h1 className="display-1 text-center text-primary font-weight-bold">404</h1>
                <h3 className="text-center">Oops! Vous semblez perdu. <i className="fa fa-history"></i></h3>
                <h6 className="text-center mb-3">La page que vous voulez consulter n'est pas disponible.</h6>
                <div className="text-center">
                  <Button color="white" className="btn btn-primary" onClick={this.home}> <i className="fa fa-home"> Retour a l'accueil</i></Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }
}

export default Page404;
