import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardGroup,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    NavItem,
    Row
} from 'reactstrap';
import {NavLink, Redirect} from "react-router-dom";
import Bounce from 'react-activity/lib/Bounce';
import {toast} from 'react-toastify';
import md5 from 'md5';
import {GetData, PostData} from '../../services/api';

class Connexion extends Component {
    constructor() {
        super();
        this.state = {
            redirect: false,
            btnText: <span><i className="fa fa-paper-plane"/> Valider</span>,
            btnDisabled: false,
            data: {
                login: '',
                mdp: '',
            }
        };
    }

    login() {
        this.setState({btnText: <Bounce color="#fff" size={14} speed={1} animating={true}/>, btnDisabled: true});
        if (this.state.data.login && this.state.data.mdp) {
            PostData('users/connection', this.state.data).then((result) => {
                this.setState({btnText: <span><i className="fa fa-paper-plane"/> Valider </span>, btnDisabled: false});
                if (result && result.status === 200) {
                    sessionStorage.setItem('yqze', btoa(JSON.stringify(result.data)));
                    sessionStorage.setItem('logLvl', "1");
                    this.setState({
                        redirect: true
                    });
                    let msg = <span><i className="fa fa-thumbs-up"/> {"Heureux de vous revoir " + result.data.firstName}</span>;
                    toast.info(msg)
                }
                else {
                    let msg = <span><i className="fa fa-times-circle"/> {result.data}</span>;
                    toast.error(msg);
                    this.setState({btnText: <span><i className="fa fa-paper-plane"/> Valider</span>, btnDisabled: false});

                }

            })
                .catch((err) => {
                    console.log(err)
                    let msg = <span><i className="fa fa-times-circle"/> Une erreur est survenue! Veuillez reessayer.</span>;
                    toast.error(msg);
                    this.setState({btnText: <span><i className="fa fa-paper-plane"/> Valider</span>, btnDisabled: false});
                });
        }
        else {
            let msg = <span><i className="fa fa-warning"/> Veuillez saisir votre email et mot de passe!</span>;
            toast.warn(msg);
            this.setState({btnText: <span><i className="fa fa-paper-plane"/> Valider</span>, btnDisabled: false});
        }
    }

    onChange(e) {
        let data = this.state.data;
        data[e.target.name] = e.target.value;

        this.setState({data: data});
    }

    onEnter(e) {
        if (e.key === 'Enter')
            this.login();
    }

    togglePass = () => {

        let inputPass = document.getElementById("password");
        let indicator = document.getElementById("hidePass");
        if (inputPass.type === "password" && indicator.className === "fa fa-eye-slash text-info") {
            indicator.className = "fa fa-eye text-success";
            inputPass.type = "text";
        }
        else {
            indicator.className = "fa fa-eye-slash text-info";
            inputPass.type = "password";
        }
    };

    render() {
        let theDate = new Date();
        let theYear = theDate.getFullYear();
        if (sessionStorage.getItem('logLvl') === "1") {
            return (<Redirect to={'/step-1'}/>)
        }
        else if (this.state.redirect) {
            return (<Redirect to={'/step-1'}/>)
        }
        else {
            return (
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="5">
                                <CardGroup>
                                    <Card className="border-0 bg-white shadow">
                                        <CardBody className="px-3 px-sm-5 py-4 text-center">
                                            <img src={require('../../ressources/images/logo.png')} alt="C'est combien logo" className="img-fluid logo mb-3"/>
                                            <h4 className="text-center mb-4">Connectez-vous</h4>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="bg-secondary text-white border-secondary">
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" name="login" placeholder="Entrez nom d'utilisateur..." onChange={(e) => this.onChange(e)}
                                                       onKeyPress={(e) => this.onEnter(e)}/>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="bg-secondary text-white border-secondary">
                                                        <i className="fa fa-lock"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input id="password" className="border-right-0" type="password" name="mdp" placeholder="Entrez votre mot de passe..."
                                                       onChange={(e) => this.onChange(e)} onKeyPress={(e) => this.onEnter(e)}/>
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText onClick={this.togglePass} className="bg-white border-left-0 pointer">
                                                        <i id="hidePass" className="fa fa-eye-slash text-info"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <Row>
                                                <Col xs="12" className="text-center">
                                                    <Button color="primary" disabled={this.state.btnDisabled} className="px-4 btn-block font-weight-bold"
                                                            onClick={() => this.login()}>{this.state.btnText}</Button>
                                                </Col>
                                            </Row>
                                            {/*<NavItem className="list-unstyled mt-2 mb-0">
                                                <NavLink exact to="/MdpOublie" className="nav-link font-weight-bold" activeClassName="text-primary">
                                                    Mot de passe oublié ?
                                                </NavLink>
                                            </NavItem>*/}
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <small className="text-muted font-weight-bold">Disting' Inter-Consultants&copy; {theYear}</small>
                                        </CardFooter>
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }
}

export default Connexion;
