import axios from  'axios';
import serversConfig from './serversConfig';

//Adresse du serveur de communication
const baseURL = serversConfig.serversLink.api;

function PostData(url, data) {
    return new Promise((resolve, reject) =>{
        fetch(baseURL+url,{
            method:'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function GetData(url) {
    return new Promise((resolve, reject) =>{
        fetch(baseURL+url,{
            method:'GET',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function PostFile(url , data) {
    return new Promise((resolve, reject) =>{
        axios.post(baseURL + url, data)
            .then(response => {
               resolve(response.data)
            })
            .catch((error)=>{
                reject(error);
            })
    });
}

export { PostData , GetData , PostFile };
