import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardGroup,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    NavItem,
    Row
} from 'reactstrap';
import {NavLink, Redirect} from "react-router-dom";
import Bounce from 'react-activity/lib/Bounce';
import { toast } from 'react-toastify';
import md5 from 'md5';
import {PostData} from '../../services/api';

class Connexion extends Component {
  constructor(){
    super();
    this.state = {
      redirect : false,
      btnText:<span><i className="fa fa-paper-plane"></i> Valider</span> ,
      btnDisabled:false,
      data:{
        email: '',
      }
    };
  }

  validateEmail() {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.data.email).toLowerCase());
  }

  valider() {
    this.setState({btnText:<Bounce color="#fff" size={14} speed={1} animating={true} /> , btnDisabled:true});
    if(this.state.data.email && this.validateEmail()){
      PostData('/utilisateur/mdp_oublie',this.state.data).then((result) => {
        let responseJson = result;
          this.setState({btnText:<span><i className="fa fa-paper-plane"></i> Valider </span>, btnDisabled:false});
        if(responseJson && responseJson.status===200){
          this.setState({
              redirect:true
          });
          let msg= <span><i className="fa fa-check-circle"></i> {responseJson.data}</span>;
          toast.info(msg)
        }
        else
        {
            let msg= <span><i className="fa fa-times-circle"></i> {responseJson.data}</span>;
            toast.error(msg);
        }
      })
      .catch(() => {
        let msg= <span><i className="fa fa-times-circle"></i> Une erreur est survenue! Veuillez reessayer.</span>;
        toast.error(msg);
        this.setState({btnText:<span><i className="fa fa-paper-plane"></i> Valider</span> , btnDisabled:false});
      });
    }
    else
    {
      let msg= <span><i className="fa fa-warning"></i> Veuillez saisir votre email !</span>;
      toast.warn(msg);
      this.setState({btnText:<span><i className="fa fa-paper-plane"></i> Valider</span> , btnDisabled:false});
    }
  }

  onChange(e){
      let data = this.state.data;

      if(e.target.type==='password' || e.target.name==='mdp')
        data[e.target.name] = md5(e.target.value);
      else
          data[e.target.name] = e.target.value;

      this.setState({data:data});
  }

  onEnter(e)
  {
    if(e.key==='Enter')
      this.valider();
  }

  render() {
    let theDate = new Date();
    let theYear = theDate.getFullYear();
    if(sessionStorage.getItem('logLvl')==="1")
    {
        return (<Redirect to={'/accueil'}/>)
    }
    else if(this.state.redirect)
    {
        return (<Redirect to={'/ReinitMdp'}/>)
    }
    else
    {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="5">
                            <CardGroup>
                                <Card className="border-0 bg-white shadow">
                                    <CardBody className="px-3 px-sm-5 py-4 text-center">
                                        <img src={require('../../ressources/images/logo.png')} alt="C'est combien logo" className="img-fluid logo mb-3"/>
                                        <h4 className="text-center mb-3">Mot de passe oublié</h4>
                                        <p className="text-center mb-4 text-muted">
                                            Vous recevrez un code de réinitialisation de mot de passe par mail.
                                            Ce code est temporaire(15 min) et confidentiel.
                                        </p>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText  className="bg-secondary text-white border-secondary">
                                                    <i className="fa fa-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" name="email" placeholder="Entrez votre email..." onChange={(e)=>this.onChange(e)} onKeyPress={(e)=>this.onEnter(e)}/>
                                        </InputGroup>
                                        <Row>
                                            <Col xs="12" className="text-center">
                                                <Button color="primary" disabled={this.state.btnDisabled} className="px-4 btn-block font-weight-bold" onClick={()=>this.valider()}>{this.state.btnText}</Button>
                                            </Col>
                                        </Row>
                                        <NavItem className="list-unstyled mt-2 mb-0">
                                            <NavLink exact to="/Connexion" className="nav-link font-weight-bold" activeClassName="text-primary">
                                                Connexion
                                            </NavLink>
                                        </NavItem>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <small className="text-muted font-weight-bold">Disting' Inter-Consultants&copy; {theYear}</small>
                                    </CardFooter>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
  }
}

export default Connexion;
