const serversLink = {
    //api: 'http://api.dist.lesdauphins.ci:8080/',
    //api: 'https://api-pdist.herokuapp.com/',
    api: 'http://164.160.35.41:7001/',
    //api: 'http://localhost:7001/',
};

module.exports = {
    serversLink : serversLink,
};
