import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Dropdown, Navbar , DropdownItem , DropdownMenu , DropdownToggle} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';
import serversConfig from '../../../services/serversConfig';

const baseURL = serversConfig.serversLink.api;

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            logout: false,
            barIcon: "bars"
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
            barIcon: (this.state.barIcon === "bars") ? "times" : "bars"
        });
    }

    setLogout() {
        const menu = <div className="text-center">
            <img src={require('../../../ressources/images/logo.png')} alt="C'est Combien?"
                 className="img-fluid logo-medium mb-3"/>
            <h6 className="text-secondary">Pesée des postes par la méthode <br/> <b>Disting'</b></h6>
            <hr className="w-25 border-primary"/>
            <h6 className="text-muted">Vous serez deconnecté. Voulez-vous continuer?</h6>
        </div>;

        confirmAlert({
            title: '',
            childrenElement: () => menu,
            buttons: [
                {
                    label: <span><i className="fa fa-check-circle"/> Oui</span>,
                    onClick: () => this.logout()
                },
                {
                    label: <span><i className="fa fa-times-circle"/> Non</span>,
                }
            ]
        })
    }

    logout() {
        sessionStorage.removeItem('logLvl');
        sessionStorage.removeItem('yqze');
        this.setState({logout: true});
    }


    render() {
     if (this.state.logout) {
            return (<Redirect to={'/'}/>)
        }

        const firstName = JSON.parse(atob(sessionStorage.getItem('yqze'))).firstName
        const logo_entreprise = JSON.parse(atob(sessionStorage.getItem('yqze'))).logo_entreprise
        const nom_entreprise = JSON.parse(atob(sessionStorage.getItem('yqze'))).nom_entreprise

        return (
            <div>
                <div>
                    <Navbar color="white" expand="md" className="shadow-sm">
                        <div className="col text-left">
                            <div className="navbar-brand m-0">
                                <img src={require('../../../ressources/images/logo.png')} alt="Disting"
                                     className="img-fluid logo-small"/>
                            </div>
                        </div>
                        <div className="col text-center">
                            <small>La pesée des postes par la méthode <strong className="text-primary">Disting'</strong> - DIC02</small>
                        </div>
                        <div className="col text-right text-primary">
                            {/*<i className="fa fa-user-circle-o mr-2"/>
                            <strong>{firstName}</strong> <br/>
                            <small className="pointer" onClick={()=>this.setLogout()}><u>Deconnexion</u></small>*/}
                            {/*<img src={baseURL+logo_entreprise} alt={nom_entreprise}
                                 className="img-fluid logo-small"/>*/}
                            <div className="row">
                                <div className="offset-6 col-3 text-right">
                                    <i className="fa fa-user-circle-o mr-2"/>
                                    <strong>{firstName}</strong> <br/>
                                    <small className="pointer" onClick={()=>this.setLogout()}><u>Deconnexion</u></small>
                                </div>
                                <div className="col-3 mt-auto mb-auto border-left ">
                                        <img src={baseURL+logo_entreprise} alt={nom_entreprise}
                                         className="img-fluid logo-small"/>

                                </div>
                            </div>
                        </div>
                    </Navbar>
                </div>
            </div>
        );
    }
}

export default NavBar;
