import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Navbar} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';

class Footer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="text-center text-muted">
                <small>Disting&copy; {new Date().getFullYear()}</small>
            </div>
        );
    }
}

export default Footer;
