import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import routes from '../../routes';
import NavBar from '../Components/uses/NavBar';
import Footer from "../Components/uses/Footer";

class Index extends Component {

  constructor(props)
  {
      super(props);
      this.state = {

      };
  }


  render() {
    if(sessionStorage.getItem('logLvl')!=="1")
    {
      return (<Redirect to={'/'}/>)
    }
    return (
        <div>
            <NavBar/>
            <main className="main pt-3">
                <Container fluid>
                    <Switch>
                        {routes.map((route, idx) => {
                                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                        <route.component {...props}/>
                                    )} />)
                                    : (null);
                            },
                        )}
                        <Redirect from="/" to="/404" />
                    </Switch>
                </Container>
            </main>
            <Footer/>
        </div>
    );
  }
}

export default Index;
