import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ToastContainer, Flip} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert';
import Connexion from './views/Pages/Connexion'
import MdpOublie from './views/Pages/MdpOublie'
import ReinitMdp from './views/Pages/ReinitMdp'
import Page404 from './views/Pages/Page404'
import Index from './views/Pages/Index'
import Menu from './views/Components/uses/Menu';
import './ressources/styles/App.scss';
import moment from "moment";


moment.locale('fr');

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
        }
    }

    showMenu() {
        if (!this.state.menuOpen) {
            this.setState({
                menuOpen: true
            });
            confirmAlert({
                title: '',
                childrenElement: () => <Menu/>,
                buttons: [
                    {
                        label: <span><i className="fa fa-times-circle"/> Fermer</span>,
                    }
                ],
                willUnmount: () => this.setState({
                    menuOpen: false
                })
            })
        }
    };

    static isMobile() {
        return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(navigator.userAgent);
    }

    componentDidMount() {
        if (document.addEventListener) { // IE >= 9 ou Autres Navigateurs
            document.addEventListener('contextmenu', (e) => {
                this.showMenu();
                e.preventDefault();
            }, false);
        } else { // IE < 9
            document.attachEvent('oncontextmenu', () => {
                App.showMenu();
                window.event.returnValue = false;
            });
        }
    }

    render() {
        return(
            <div>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" name="Connexion" component={Connexion}/>
                        <Route exact path="/MdpOublie" name="MdpOublie" component={MdpOublie}/>
                        <Route exact path="/ReinitMdp" name="ReinitMdp" component={ReinitMdp}/>
                        <Route exact path="/404" name="Page 404" component={Page404}/>
                        <Route path="/" name="Dising" component={Index}/>
                    </Switch>
                </BrowserRouter>
                <ToastContainer transition={Flip} position="top-center"/>
            </div>
        )
    }
}

export default App;
