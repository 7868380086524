import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter , CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import {Redirect} from "react-router-dom";
import Bounce from 'react-activity/lib/Bounce';
import { toast } from 'react-toastify';
import md5 from 'md5';
import {PostData} from '../../services/api';

class Connexion extends Component {
  constructor(){
    super();
    this.state = {
      redirect : false,
      btnText:<span><i className="fa fa-paper-plane"/> Valider</span> ,
      btnDisabled:false,
      data:{
        code: '',
        mdp: '',
      }
    };
  }

  valider() {
    this.setState({btnText:<Bounce color="#fff" size={14} speed={1} animating={true} /> , btnDisabled:true});
    if(this.state.data.code && this.state.data.mdp){
      PostData('/utilisateur/reset_mdp',this.state.data).then((result) => {
        let responseJson = result;
          this.setState({btnText:<span><i className="fa fa-paper-plane"/> Valider </span>, btnDisabled:false});
        if(responseJson && responseJson.status===200){
          this.setState({
              redirect:true
          });
          let msg= <span><i className="fa fa-check-circle"></i> {responseJson.data}</span>;
          toast.info(msg)
        }
        else
        {
            let msg= <span><i className="fa fa-times-circle"></i> {responseJson.data}</span>;
            toast.error(msg);
        }
      })
      .catch(() => {
        let msg= <span><i className="fa fa-times-circle"></i> Une erreur est survenue! Veuillez reessayer.</span>;
        toast.error(msg);
        this.setState({btnText:<span><i className="fa fa-paper-plane"></i> Valider</span> , btnDisabled:false});
      });
    }
    else
    {
      let msg= <span><i className="fa fa-warning"></i> Veuillez saisir le code et votre nouveau mot de passe!</span>;
      toast.warn(msg);
      this.setState({btnText:<span><i className="fa fa-paper-plane"></i> Valider</span> , btnDisabled:false});
    }
  }

  onChange(e){
      let data = this.state.data;

      if(e.target.type==='password' || e.target.name==='mdp')
        data[e.target.name] = md5(e.target.value);
      else
          data[e.target.name] = e.target.value;

      this.setState({data:data});
  }

  onEnter(e)
  {
    if(e.key==='Enter')
      this.valider();
  }

  togglePass = ()=>{

    let inputPass =  document.getElementById("password");
    let indicator =  document.getElementById("hidePass");
    if(inputPass.type==="password" && indicator.className==="fa fa-eye-slash text-info")
    {
        indicator.className = "fa fa-eye text-success";
        inputPass.type = "text";
    }
    else
    {
        indicator.className = "fa fa-eye-slash text-info";
        inputPass.type = "password";
    }
  };

  render() {
    let theDate = new Date();
    let theYear = theDate.getFullYear();
    if(sessionStorage.getItem('logLvl')==="1")
    {
        return (<Redirect to={'/accueil'}/>)
    }
    else if(this.state.redirect)
    {
        return (<Redirect to={'/Connexion'}/>)
    }
    else
    {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" lg="5">
                            <CardGroup>
                                <Card className="border-0 bg-white shadow">
                                    <CardBody className="px-3 px-sm-5 py-4 text-center">
                                        <img src={require('../../ressources/images/logo.png')} alt="C'est combien logo" className="img-fluid logo mb-3"/>
                                        <h4 className="text-center mb-4">Réinitialisation de mot de passe</h4>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText  className="bg-secondary text-white border-secondary">
                                                    <i className="fa fa-unlock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="password" name="code" placeholder="Entrez le code de réinitialisation..." onChange={(e)=>this.onChange(e)} onKeyPress={(e)=>this.onEnter(e)}/>
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText className="bg-secondary text-white border-secondary">
                                                    <i className="fa fa-lock"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input id="password" className="border-right-0" type="password" name="mdp" placeholder="Entrez votre nouveau mot de passe..." onChange={(e)=>this.onChange(e)} onKeyPress={(e)=>this.onEnter(e)} />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText onClick={this.togglePass} className="bg-white border-left-0 pointer">
                                                    <i id="hidePass" className="fa fa-eye-slash text-info"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <Row>
                                            <Col xs="12" className="text-center">
                                                <Button color="primary" disabled={this.state.btnDisabled} className="px-4 btn-block font-weight-bold" onClick={()=>this.valider()}>{this.state.btnText}</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <small className="text-muted font-weight-bold">Disting' Inter-Consultants&copy; {theYear}</small>
                                    </CardFooter>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
  }
}

export default Connexion;
