import React from 'react';
import Loadable from 'react-loadable'
import Bounce from 'react-activity/lib/Bounce';
import 'react-activity/lib/Bounce/Bounce.css';
import Index from './views/Pages/Index';

function Loading() {
  return <div className="mt-5 pt-5 text-center">
          <div className="mt-5 pt-5">
            <Bounce color="#727981" size={20} speed={1} animating={true} />
            <p className="text-muted">Chargement...</p>
          </div>
      </div>
}


const Step1 = Loadable({
    loader: () => import('./views/Components/main/Step1'),
    loading: Loading,
});

const Step2 = Loadable({
    loader: () => import('./views/Components/main/Step2'),
    loading: Loading,
});

const Step3 = Loadable({
    loader: () => import('./views/Components/main/Step3'),
    loading: Loading,
});


const routes = [
  { path: '/', exact: true, name:"Disting'", component: Index },
  { path: '/step-1', name: 'Gestion des pesée', component: Step1 },
  { path: '/step-2', name: 'Gestion des fiche de poste', component: Step2 },
  { path: '/step-3', name: 'Synthèse', component: Step3 },

];

export default routes;
