import React, { Component } from 'react';
class Menu extends Component {

    render() {
        return (
            <div className="animated fadeIn">
                <div>
                    <div className="text-center">
                        <img src={require('../../../ressources/images/logo.png')} alt="C'est Combien?" className="img-fluid logo-medium mb-3"/>
                        <h6 className="text-secondary">Pesée des postes par la méthode <br/> <b>Disting'</b></h6>
                    </div>
                    <hr/>
                   <p className="text-center"> <small className="font-weight-bold text-primary">Version 2.0.1</small></p>
                </div>
            </div>
        );
    }
}

export default Menu;
